import React from 'react'
import { Block, Content, Yoga } from 'gerami'
import Markdown from 'markdown-to-jsx'

import { YouthQuery } from '../../../../../../graphql-types'
import Anchor from '../../../../../shared/components/anchor/anchor'

type YouthEventsProps = Exclude<
  Exclude<YouthQuery['youthPageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['events']

const YouthEvents: React.FC<YouthEventsProps> = ({
  event1Title,
  event1Description,
  event1BtnText,
  event1BtnUrl,
  event2Title,
  event2Description,
  event2BtnText,
  event2BtnUrl
}) => {
  return (
    <div
      className="bg-primary inset-shade-L inset-shade-M inset-shade-S"
      style={{
        backgroundImage: `linear-gradient(45deg, transparent, rgba(255,255,255,0.42), transparent)`
      }}
    >
      <Content
        transparent
        size="8XL"
        className="margin-vertical-very-big padding-vertical-very-big"
      >
        <Block first>
          <h1 className="center text-shade-L text-shade-M text-shade-S">
            Youth Events
          </h1>
        </Block>

        <Block last>
          <Yoga maxCol={3} className="center">
            <Content>
              <h3>
                <Block>{event1Title}</Block>
                <hr style={{ opacity: 0.42 }} />
                <Block className="subtitle font-M padding-bottom-none">
                  <Markdown>{event1Description || ''}</Markdown>
                </Block>
              </h3>
              {!(event1BtnText && event1BtnUrl) ? null : (
                <Block last className="padding-top-none">
                  <Anchor
                    to={event1BtnUrl}
                    className="gerami-button gerami-button-primary margin-top-very-big"
                  >
                    <span className="inline-block middle">{event1BtnText}</span>
                  </Anchor>
                </Block>
              )}
            </Content>

            <Content>
              <h3>
                <Block>{event2Title}</Block>
                <hr style={{ opacity: 0.42 }} />
                <Block className="subtitle font-M padding-bottom-none">
                  <Markdown>{event2Description || ''}</Markdown>
                </Block>
              </h3>{' '}
              {!(event2BtnText && event2BtnUrl) ? null : (
                <Block last className="padding-top-none">
                  <Anchor
                    to={event2BtnUrl}
                    className="gerami-button gerami-button-primary margin-top-very-big"
                  >
                    <span className="inline-block middle">{event2BtnText}</span>
                  </Anchor>
                </Block>
              )}
            </Content>
          </Yoga>
        </Block>
      </Content>
    </div>
  )
}

export default YouthEvents
