import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { YouthQuery } from '../../../../graphql-types'
import SEO from '../../../shared/components/seo/seo'
import Layout from '../../../shared/components/layout/layout'
import YouthLead from './components/youth-lead/youth-lead'
import YouthSchools from './components/youth-schools/youth-schools'
import YouthEvents from './components/youth-events/youth-events'
import AnotherSection from '../../components/another-section/another-section'
import EmailRegistration from '../../../shared/components/email-registration/email-registration'

type YouthProps = {}

const Youth: React.FC<YouthProps> = ({}) => {
  const data = useStaticQuery<YouthQuery>(query)
  const { lead, schools, events, otherSections } =
    data.youthPageInfo?.frontmatter ?? {}

  return (
    <>
      <SEO title="Youth" />

      <Layout>
        {!lead?.isEnabled ? null : <YouthLead {...lead} />}
        {!schools?.isEnabled ? null : <YouthSchools {...schools} />}
        {!events?.isEnabled ? null : <YouthEvents {...events} />}
        {otherSections?.map((anotherSection, i) =>
          !anotherSection?.isEnabled ? null : (
            <AnotherSection key={i} {...anotherSection} />
          )
        ) || null}
        <EmailRegistration />
      </Layout>
    </>
  )
}

export default Youth

const query = graphql`
  query Youth {
    youthPageInfo: markdownRemark(
      fields: { slug: { eq: "/info/youth-page/" } }
    ) {
      frontmatter {
        lead {
          isEnabled
          bg
          title
          description
        }
        schools {
          isEnabled
          campusTitle
          campusDescription
          highSchoolTitle
          highSchoolDescription
        }
        events {
          isEnabled
          event1Title
          event1Description
          event1BtnText
          event1BtnUrl
          event2Title
          event2Description
          event2BtnText
          event2BtnUrl
        }
        otherSections {
          isEnabled
          bg
          align
          title
          description
          btnText
          btnUrl
        }
      }
    }
  }
`
