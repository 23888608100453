import React from 'react'
import { Block, Content, Yoga } from 'gerami'

import { YouthQuery } from '../../../../../../graphql-types'
import Markdown from 'markdown-to-jsx'

type YouthSchoolsProps = Exclude<
  Exclude<YouthQuery['youthPageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['schools']

const YouthSchools: React.FC<YouthSchoolsProps> = ({
  campusTitle,
  campusDescription,
  highSchoolTitle,
  highSchoolDescription
}) => {
  return (
    <div>
      <Content
        transparent
        size="4XL"
        className="margin-vertical-very-big padding-vertical-very-big"
      >
        <Block first last>
          <Yoga maxCol={3} className="center">
            <Content className="center">
              <h3>
                <Block>{campusTitle}</Block>
                <hr style={{ opacity: 0.42 }} />
                <Block last className="subtitle font-M">
                  <Markdown>{campusDescription || ''}</Markdown>
                </Block>
              </h3>
            </Content>

            <Content className="center">
              <h3>
                <Block>{highSchoolTitle}</Block>
                <hr style={{ opacity: 0.42 }} />
                <Block last className="subtitle font-M">
                  <Markdown>{highSchoolDescription || ''}</Markdown>
                </Block>
              </h3>
            </Content>
          </Yoga>
        </Block>
      </Content>
    </div>
  )
}

export default YouthSchools
