import React, { useMemo } from 'react'
import { Parallax } from 'react-parallax'
import { Block, Content } from 'gerami'
import Markdown from 'markdown-to-jsx'

import './another-section.scss'
import Anchor from '../../../shared/components/anchor/anchor'

type AnotherSectionProps = {
  bg?: string | null
  align?: 'center' | 'left' | 'right' | string | null
  title?: string | null
  description?: string | null
  btnText?: string | null
  btnUrl?: string | null
}

const AnotherSection: React.FC<AnotherSectionProps> = ({
  bg,
  align: _align,
  title,
  description,
  btnText,
  btnUrl
}) => {
  const align = useMemo(
    () =>
      _align && [`center`, `left`, `right`].includes(_align.toLowerCase())
        ? _align.toLowerCase()
        : `center`,
    [_align]
  )

  const BgComponent = ({ children }: React.PropsWithChildren<{}>) =>
    !!bg ? (
      <Parallax bgImage={`${bg}?nf_resize=fit&w=1080`} strength={300}>
        {children}
      </Parallax>
    ) : (
      <div>{children}</div>
    )

  return (
    <div className={`${!!bg ? 'another-section ' : ''}`}>
      <BgComponent>
        <div
          className={`${
            !!bg
              ? 'another-section-overlay inset-shade-L inset-shade-M inset-shade-S fg-whitish '
              : ''
          }padding-vertical-very-big`}
        >
          <Content
            transparent
            size="XL"
            className={`${align} margin-vertical-very-big padding-vertical-very-big`}
          >
            {!title ? null : (
              <Block first last={!(description || (btnText && btnUrl))}>
                <h1
                  className={`${!!bg ? 'fg-white' : ''} ${
                    !!description ? 'padding-top-none' : ''
                  }`}
                >
                  {title}
                </h1>
              </Block>
            )}

            {!description ? null : (
              <Block last={!(btnText && btnUrl)}>
                <article
                  className={`${align} ${!!bg ? 'fg-whitish' : ''}`}
                  style={{ fontFamily: 'inherit' }}
                >
                  <Markdown>{description}</Markdown>
                </article>
              </Block>
            )}

            {!(btnText && btnUrl) ? null : (
              <Block last>
                <Anchor
                  to={btnUrl}
                  target="_blank"
                  rel="noopener nofollow"
                  className={`gerami-button ${
                    !!bg ? 'gerami-button-primary' : ''
                  } margin-top-very-big`}
                >
                  {btnText}
                </Anchor>
              </Block>
            )}
          </Content>
        </div>
      </BgComponent>
    </div>
  )
}

export default AnotherSection
